var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth-wrapper auth-v2" },
    [
      _c(
        "b-row",
        { staticClass: "auth-inner m-0" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-none d-lg-flex align-items-center p-5",
              attrs: { lg: "8" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-lg-flex align-items-center justify-content-center px-5"
                },
                [
                  _c("b-img", {
                    attrs: {
                      fluid: "",
                      src: _vm.imgUrl,
                      alt: "Forgot password V2"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center auth-bg px-2 p-lg-5",
              attrs: { lg: "4" }
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "px-xl-2 mx-auto",
                  attrs: { sm: "8", md: "6", lg: "12" }
                },
                [
                  _c("b-card-title", { staticClass: "mb-1" }, [
                    _vm._v(" Forgot Password? ")
                  ]),
                  _c("b-card-text", { staticClass: "mb-2" }, [
                    _vm._v(
                      " Enter your email and we'll send you instructions to reset your password "
                    )
                  ]),
                  _c(
                    "validation-observer",
                    { ref: "simpleRules" },
                    [
                      _c(
                        "b-form",
                        {
                          staticClass: "auth-forgot-password-form mt-2",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.validationForm($event)
                            }
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Email",
                                "label-for": "forgot-password-email"
                              }
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Email",
                                  rules: "required|email"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "forgot-password-email",
                                            state:
                                              errors.length > 0 ? false : null,
                                            name: "forgot-password-email",
                                            placeholder: "john@example.com"
                                          },
                                          model: {
                                            value: _vm.userEmail,
                                            callback: function($$v) {
                                              _vm.userEmail = $$v
                                            },
                                            expression: "userEmail"
                                          }
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "submit",
                                variant: "primary",
                                block: "",
                                disabled: _vm.preventDoubleClick
                              }
                            },
                            [_vm._v(" Send reset code ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "text-center mt-2" },
                    [
                      _c(
                        "b-link",
                        { attrs: { to: { name: "auth-login" } } },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "ChevronLeftIcon" }
                          }),
                          _vm._v(" Back to login ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }